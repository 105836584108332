<template>
  <div class='bow-verify-otp-main-wrapper'>
    <div class='bow-login-sub-div-wrapper'>
      <!-- <div class='wizzer-logo'>WiZZER</div> -->
      <div class='form-wrapper'>
        <div class='verification-text-wrapper'>
          <span class='verification-client-id'>{{ clientId }}</span>
        </div>
        <div class='verification-sub-text-wrapper'>
          <span class='verification-sub-text'>{{ otpMessage }}</span>
        </div>
        <div class='otp-field'>
          <v-otp-input
            ref='otpInput'
            separator=''
            input-classes='otp-input'
            :num-inputs='6'
            :should-auto-focus='true'
            :is-input-num='true'
            @on-change='handleOnChange'
            @on-complete='handleOnComplete'
          />
        </div>
        <div v-if='otpValidation' style='color: red; text-align: left; padding-left: 33px'>
          Please enter OTP
        </div>
        <div class='otp-text'>
          Didn’t receive the OTP? <span class='resend-text'
          @click="onClickReSendOtp">Resend OTP</span>
        </div>
        <div class='verify-otp-wrapper'>
          <div class='verify-sub-wrapper'>
            <a-button class='verify-button' type='primary' @click='onClickVerify'>
              Verify
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import VOtpInput from 'vue3-otp-input';
import services from '../../../services/apis';

export default {
  components: {
    VOtpInput,
  },
  setup() {
    const route = useRouter();
    const store = useStore();
    const clientId = ref('');
    const otpMessage = ref('');
    let bowResponse;
    onMounted(() => {
      window.scrollTo(0, 0);
      bowResponse = computed(() => store.getters['onboardingStore/getBowResponse']).value;
      clientId.value = bowResponse.clientId;
      otpMessage.value = bowResponse.message;
    });
    const otpInput = ref(null);
    const otpValidation = ref(false);
    let otpValue = 0;
    const handleOnComplete = () => {
      otpValidation.value = false;
    };
    const handleOnChange = (value) => {
      otpValue = value;
      if (value.length) {
        otpValidation.value = false;
      }
    };

    const onClickVerify = () => {
      if (otpValue.length < 6) {
        otpValidation.value = true;
      } else if (!otpValue.length) {
        otpValidation.value = true;
      } else if (otpValue.length === 6) {
        const data = {
          clientId: clientId.value,
          otp: otpValue,
        };
        services
          .bowVerifyOtp(data)
          .then((response) => {
            message.success(response.data.message, 2);
            route.push('/admin/admin-dashboard');
          })
          .catch((e) => {
            message.error(e.response.data.error.message, 2);
          });
      }
    };
    const onClickReSendOtp = () => {
      if (clientId.value) {
        const payload = {
          clientId: clientId.value,
        };
        services
          .bowResendOtp(payload)
          .then((response) => {
            message.success(response.data.message, 2);
          })
          .catch((e) => {
            message.error(e.response.data.error.message, 2);
          });
      }
    };

    return {
      handleOnChange,
      handleOnComplete,
      otpInput,
      onClickVerify,
      otpValidation,
      otpMessage,
      clientId,
      onClickReSendOtp,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/BowVerifyOtp.scss';
</style>
